import React from 'react';
import {Link} from "react-router-dom";
import {FaHome} from "react-icons/fa";

const NotFound = () =>
    <div className="hero">
        <div className="text-center hero-content">
            <div className="max-w-lg">
                <h2 className="text-7xl font-bold mb-8">Ooops!</h2>
                <p className="text-4xl mb-8">404 - Page not found!</p>
                <Link to="/" className="btn btn-primary btn-lg">
                    <FaHome className="mr-2"/>
                    Back to homepage
                </Link>
            </div>
        </div>
    </div>

export default NotFound;
