import React, {useContext, useState} from 'react'
// Context
import GithubContext from "../../context/github/GithubContext"
import AlertContext from "../../context/alert/AlertContext"
// Actions
import {searchUsers} from "../../context/github/GithubActions"

const UserSearch = () => {
    // State
    const [text, setText] = useState('')
    // Context
    const {users, dispatch} = useContext(GithubContext)
    const {setAlert} = useContext(AlertContext)

    /**
     * Form input change handle
     * @param e
     */
    const handleChange = e => setText(e.target.value)

    /**
     * Form submit handle
     * @param e
     * @returns {Promise<void>}
     */
    const handleSubmit = async (e) => {
        e.preventDefault()
        // Check input is not empty
        if (text === '') {
            // Show alert message
            setAlert('Please enter something', 'error')
        } else {
            // Change action - loading
            dispatch({type: 'SET_LOADING'})
            // Get users
            const users = await searchUsers(text)
            // Change action - get users
            dispatch({type: 'GET_USERS', payload: users})
            // Reset inout value
            setText('')
        }
    }

    return <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 mb-8 gap-8">
        <div>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <div className="relative">
                        <input
                            type="text"
                            className="w-full pr-40 bg-gray-200 input input-lg text-black"
                            placeholder="Search"
                            value={text}
                            onChange={handleChange}
                        />
                        <button className="absolute top-0 right-0 rounded-l-none w-36 btn btn-lg" type="submit">
                            Go
                        </button>
                    </div>
                </div>
            </form>
        </div>

        {users.length > 0 && (
            <div>
                <button
                    className="btn btn-ghost btn-lg"
                    onClick={()=>dispatch({type: 'SET_CLEAR_RESULT'})}
                >
                    Clear
                </button>
            </div>
        )}
    </div>
}

export default UserSearch
