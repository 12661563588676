import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
// Components
import Navbar from "./components/layout/Navbar"
import Footer from "./components/layout/Footer"
import Alert from "./components/layout/Alert"
// Pages
import Home from "./components/pages/Home"
import About from "./components/pages/About"
import User from "./components/pages/User"
import NotFound from "./components/pages/NotFound"
// Context
import {GithubProvider} from "./context/github/GithubContext"
import {AlertProvider} from "./context/alert/AlertContext"

const App = () =>
    <GithubProvider>
        <AlertProvider>
            <Router>
                <div className="flex flex-col justify-between h-screen">
                    <Navbar/>
                    <main className="container mx-auto px-3 pb-12">
                        <Alert/>
                        <Routes>
                            <Route path="/" exact element={<Home/>}/>
                            <Route path="/about" exact element={<About/>}/>
                            <Route path="/user/:login" exact element={<User/>}/>
                            <Route path="/notfound" exact element={<NotFound/>}/>
                            <Route path="/*" exact element={<NotFound/>}/>
                        </Routes>
                    </main>
                    <Footer/>
                </div>
            </Router>
        </AlertProvider>
    </GithubProvider>

export default App
