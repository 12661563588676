import React from 'react';
// Components
import UserResults from "../users/UserResults";
import UserSearch from "../users/UserSearch";

const Home = () =>
    <div>
        <UserSearch/>
        <UserResults/>
    </div>

export default Home;
