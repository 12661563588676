import axios from "axios";

/**
 * Axios setup
 * @type {AxiosInstance}
 */
const github = axios.create({
    baseURL: process.env.REACT_APP_GITHUB_URL,
    headers: {Authorization: `token ${process.env.REACT_APP_GITHUB_TOKEN}`}
})

/**
 * Search users
 * @param text
 * @returns {Promise<*>}
 */
export const searchUsers = async (text) => {
    const params = new URLSearchParams({q: text})
    const response = await github.get(`/search/users?${params}`)
    return response.data.items
}

/**
 * Get user and ser repos data
 * @param login
 * @returns {Promise<{repos: any, user: any}>}
 */
export const getUserAndRepos = async (login) => {
    const [user, repos] = await Promise.all([
        github.get(`/users/${login}`),
        github.get(`/users/${login}/repos`)
    ])

    return {user: user.data, repos: repos.data}
}